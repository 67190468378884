<template>
  <a-button
    type="primary"
    size="large"
    ghost
    :disabled="enumeratorDisabled"
    :loading="downloading"
    @click="downloadReport">
    {{ $t('components.labels.standardizationTestReport') }}
  </a-button>
</template>

<script>
import { mapActions } from 'vuex';
import { downloadBase64 } from '../../../../../util/standardization';

export default {
  name: 'StandardizationReportButton',
  props: {
    groupId: {
      type: Number,
      required: true
    },
    enumeratorDisabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      downloading: false
    };
  },
  methods: {
    ...mapActions(['getTemBiasReportForGroup']),
    async downloadReport() {
      try {
        this.downloading = true;
        const standardizationReport = await this.getTemBiasReportForGroup({
          groupId: this.groupId,
          lang: this.$i18n.locale
        });

        if (standardizationReport) {
          downloadBase64(
            standardizationReport,
            'standardization-report.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
        } else {
          throw new Error('An error occured generating report.');
        }
      } catch {
        this.$alert().danger(this.$t('components.notifications.anErrorOccuredGeneratingReport'));
      } finally {
        this.downloading = false;
      }
    }
  }
};
</script>

<style lang="scss">
</style>
